import Auth from "cp-client-auth!sofe";

export default function (loggedInUserPromise, tenantPromise) {
  return Promise.all([loggedInUserPromise, tenantPromise]).then((values) => {
    const [user, tenant] = values;

    if (!user || !tenant) {
      return;
    }

    if (
      tenant.subscription_ended &&
      !user.preferences.dismissedTrialExpiration
    ) {
      // Transcripts is the default home page for "freemium" users.
      window.singleSpa.navigateToUrl("/#/transcripts/list");
    }

    Auth.trackFreemiumUser(user);
  });
}
