import { setUser, init } from "error-logging!sofe";
import Auth from "cp-client-auth!sofe";
export default function () {
  return Promise.resolve().then(() => {
    if (!window.appLoaderVersion) {
      throw new Error(
        `Cannot initialize sentry without window.appLoaderVersion`
      );
    }

    init(
      `app-loader-ui_${window.appLoaderVersion}`,
      null,
      null,
      window.sofe_manifest || {}
    );

    /* This observable never should call onCompleted() or onError(), so normally we would need to dispose of our subscription
     * when we are done with it to avoid memory leaks. However, in this case we are never done with it until the page reloads
     * and the memory is cleaned up anyway.
     */
    Auth.getLoggedInUserAsObservable().subscribe((user) => setUser(user));
  });
}
