import { catchAsyncStacktrace } from "auto-trace";
import Auth from "cp-client-auth!sofe";

export async function fullStoryIdentify() {
  Auth.getLoggedInUserAsObservable().subscribe((loggedInUser) => {
    if (window.FS) {
      const { id, email, name, tenantId, licenses } = loggedInUser;
      window.FS.identify(id, { email, displayName: name, tenantId, licenses });
    }
  }, catchAsyncStacktrace());
}
